<template lang="html">
  <div>
    <a href="https://www.freeprivacypolicy.com/live/e320fea8-fd31-4127-970d-afe6d13664c3"
      >Check Privacy Policy</a
    >
  </div>
</template>
<script>
export default {
  name: "privacy_policy",
  metaInfo: {
    title: "Privacy policy",
  },
};
</script>
<style lang="scss" scoped></style>
