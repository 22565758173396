<template lang="html">
  <div class="container registration-page">
    <div class="container" v-if="!type_choosed">
      <h2 class="text-center">{{ localizedTextFct("register", "registration") }}</h2>
      <h3 class="text-center">
        {{ localizedTextFct("register", "are_u") }}
        <span class="options-name">{{ type }}</span>
        ?
      </h3>
    </div>
    <div class="container" v-if="!type_choosed">
      <div class="row">
        <div class="col-sm reg-button-container">
          <img
            src="@/assets/img/student_new.jpg"
            class="img-fluid mx-auto reg-img"
            alt="student"
            @click="activate('student')"
            @mouseover="type = localizedTextFct('register', 'student')"
            @mouseout="type = '...'"
          />
          <p class="reg-type">{{ localizedTextFct("register", "Student") }}</p>
        </div>
        <div class="col-sm reg-button-container">
          <img
            src="@/assets/img/teacher_new.jpg"
            class="img-fluid mx-auto reg-img"
            alt="teacher"
            @click="activate('teacher')"
            @mouseover="type = localizedTextFct('register', 'teacher')"
            @mouseout="type = '...'"
          />
          <p class="reg-type">{{ localizedTextFct("register", "Teacher") }}</p>
        </div>
      </div>
    </div>
    <div class="container" id="form_container" v-show="type_choosed">
      <h2 class="text-center">
        {{ localizedTextFct("register", "registration") }}
      </h2>
      <form id="register_form" autocomplete="on" @submit.prevent="handleRegister">
        <div class="form-group hidden">
          <div class="form-check">
            <label class="form-check-label" for="radio_student">
              <input
                type="radio"
                class="form-check-input"
                id="radio_student"
                name="user_type"
                value="student"
                ref="radio_student"
                v-model="user.user_type"
                required
              />{{ localizedTextFct("register", "Student") }}
            </label>
          </div>
          <div class="form-check">
            <label class="form-check-label" for="radio_teacher">
              <input
                type="radio"
                class="form-check-input"
                id="radio_teacher"
                name="user_type"
                value="teacher"
                ref="radio_teacher"
                v-model="user.user_type"
              />{{ localizedTextFct("register", "teacher") }}
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="username">{{
            this.localizedTextFct("register", "username") + ":"
          }}</label>
          <input
            type="text"
            :placeholder="localizedTextFct('register', 'username')"
            name="username"
            id="username"
            class="form-control"
            autocomplete="username"
            required
            pattern="^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$"
            oninvalid="this.setCustomValidity('Username may only contain letters or numbers and must be between 8 and 30 length')"
            oninput="this.setCustomValidity('')"
            maxlength="30"
            v-model="user.username"
            v-validate="{
              required: true,
              min: 2,
              max: 30,
              regex: /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/,
            }"
          />
          <div v-if="submitted && errors.has('username')" class="alert-danger">
            {{ errors.first("username") }}
          </div>
        </div>

        <div class="form-group">
          <label for="email">{{
            this.localizedTextFct("register", "email_address") + ":"
          }}</label>
          <input
            type="text"
            :placeholder="localizedTextFct('register', 'email_address')"
            name="email"
            id="email"
            class="form-control"
            autocomplete="email"
            required
            maxlength="45"
            v-model="user.email"
            v-validate="'required|email|max:45'"
          />
          <div v-if="submitted && errors.has('email')" class="alert-danger">
            {{ errors.first("email") }}
          </div>
        </div>

        <div class="form-group">
          <label for="password">{{
            this.localizedTextFct("register", "password") + ":"
          }}</label>
          <input
            type="password"
            :placeholder="localizedTextFct('register', 'password')"
            name="password"
            id="password"
            class="form-control"
            autocomplete="new-password"
            required
            v-model="user.password"
            ref="password"
            v-validate="'required|min:2|max:30'"
          />
          <div v-if="submitted && errors.has('password')" class="alert-danger">
            {{ errors.first("password") }}
          </div>
        </div>

        <div class="form-group">
          <label for="password2">{{
            this.localizedTextFct("register", "repeat_password") + ":"
          }}</label>
          <input
            type="password"
            :placeholder="localizedTextFct('register', 'repeat_password')"
            name="password2"
            id="password2"
            class="form-control"
            autocomplete="new-password"
            required
            v-model="user.password2"
            v-validate="'required|min:2|max:30|confirmed:password'"
          />
          <div v-if="submitted && errors.has('password2')" class="alert-danger">
            {{ errors.first("password2") }}
          </div>
        </div>
        <div class="form-group">
          <div class="form-checkbox">
            <input
              name="terms_of_service"
              type="checkbox"
              id="terms_of_service"
              v-validate="'required'"
              class="mr-2"
            />
            <label for="terms_of_service" class="m-0">
              I have read and understood the
              <a target="_blank" href="/terms_of_service"> Terms of Service </a> and
              <a
                target="_blank"
                href="https://www.freeprivacypolicy.com/live/094b03cc-5b8d-455b-8a7d-49cb73d9c33d"
                >Privacy Policy</a
              >
            </label>
          </div>
          <div v-if="submitted && errors.has('terms_of_service')" class="alert-danger">
            Please press the button above to confirm that you agree with the terms of
            service
          </div>
        </div>
        <div class="form-group">
          <VueRecaptcha
            :loadRecaptchaScript="true"
            ref="recaptcha"
            @verify="onCaptchaVerified"
            @expired="onCaptchaExpired"
            size="invisible"
            :sitekey="recaptchaValue"
          >
          </VueRecaptcha>
          <button
            type="submit"
            :disabled="status === 'submitting'"
            class="btn btn-primary"
            id="reg_btn"
          >
            {{ this.localizedTextFct("register", "submit") }}
          </button>
        </div>
        <MessageBox :messages="messages" :message_type="message_type" />
      </form>
    </div>
    <div class="container" id="signin" v-show="type_choosed">
      <p class="account-question">
        {{ this.localizedTextFct("register", "have_account") }}
        <router-link :to="{ name: 'login' }">{{
          this.localizedTextFct("login", "login")
        }}</router-link
        >.
      </p>
    </div>
  </div>
</template>

<script>
//import models
import User from "@/models/user.js";

//import components
import MessageBox from "@/components/common/MessageBox.vue";
import VueRecaptcha from "vue-recaptcha";

//import mixins
import handleServerMessage from "@/mixins/handleServerMessage.js";

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default {
  name: "Register",
  metaInfo: {
    title: "Register",
  },
  mixins: [handleServerMessage],
  components: {
    VueRecaptcha,
    MessageBox,
  },
  props: {
    user_type: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      user: new User("", "", "", "", ""),
      submitted: false,
      successful: false,
      messages: [],
      message_type: [],
      type_choosed: false,
      type: "...",
      status: "",
    };
  },
  mounted() {
    if (
      this.$route.params.user_type === "student" ||
      this.$route.params.user_type === "teacher"
    ) {
      this.activate(this.$route.params.user_type);
    } else if (this.user_type === "student" || this.user_type === "teacher") {
      this.activate(this.user_type);
    }
    if (this.email) {
      this.user.email = this.email;
    }
  },
  methods: {
    activate(type) {
      if (type == "student" || type == "teacher") {
        let radio_button = "radio_" + type;
        this.$refs[radio_button].click();
        this.type_choosed = true;
      }
    },
    handleRegister: function () {
      this.$refs.recaptcha.execute();
    },
    onCaptchaVerified: function (recaptchaToken) {
      let self = this;
      self.submitted = true;
      self.$refs.recaptcha.reset();
      self.$validator.validate().then(function (isValid) {
        if (isValid) {
          self.status = "submitting";
          let payload = {
            user: self.user,
            recaptchaToken: recaptchaToken,
            user_time_zone: localTimeZone,
          };
          self.$store
            .dispatch("auth/register", payload)
            .then((res) => {
              self.successful = true;
              self.status = "";
              self.handleServerMessage(res, "res", 3000);
              if (payload.user.user_type == "student") {
                self.$store.dispatch("auth/login", self.user).then((user) => {
                  if (user.user_type == "student") {
                    window.location.href = `/students/${self.$i18n.locale}/homepage`;
                  } else if (user.user_type == "teacher") {
                    window.location.href = `/teachers/${self.$i18n.locale}/homepage`;
                  }
                });
              }
            })
            .catch((err) => {
              self.handleServerMessage(err, "err", 5000);
              self.status = "";
              self.successful = false;
            });
        }
      });
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
  },
  computed: {
    recaptchaValue() {
      return process.env.VUE_APP_RECAPTCHA;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";

.reg-type {
  font-size: 20px;
  font-family: $fontFamilyCommon;
}

.reg-img {
  display: block;
  text-align: center;
  width: 306px;
  height: 240px;
  cursor: pointer;
  border-radius: 4px;
  &:hover,
  &:active {
    box-shadow: 4px 4px 1px 1px rgba(0, 0, 0, 0.3), 0px -1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.reg-button-container {
  padding: 5px;
  text-align: center;
}

.alert-danger {
  padding-left: 5px;
  border-radius: 2px;
}

.hidden {
  display: none;
}

.form-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#form_container,
#signin {
  max-width: 500px;
}

#add_fields,
#reg_btn {
  width: 100%;
}

@media screen and (max-height: 800px) {
  .form-group {
    margin-bottom: 0;
  }
  label {
    margin-bottom: 0;
  }
  #signin {
    visibility: hidden;
  }

  .registration-page {
    font-size: $fontSizeMedium;
  }

  #reg_btn {
    margin-top: 1em;
  }
}
</style>
