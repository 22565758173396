export default {
  data() {
    return {
      event_scheduled: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('message', this.handleCalendlyEvent );
    })
  },
  beforeDestroy() {
    window.removeEventListener('messsage', this.handleCalendlyEvent);
  },
  methods: {
    handleCalendlyEvent(e) {
      if (this.isCalendlyEvent(e)) {
        if(e.data.event === 'calendly.event_scheduled') {
          this.event_scheduled = true;
        }
      }
    },
    isCalendlyEvent(e) {
      return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
    }
  }
}