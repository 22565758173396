<template>
  <div id="app">
    <VisitorsNavBar />
    <notifications animation-type="velocity" group="main" position="bottom right" />
    <router-view />
  </div>
</template>

<script>
import VisitorsNavBar from "@/components/visitors/VisitorsNavBar";
export default {
  name: "app",
  components: {
    VisitorsNavBar,
  },
  data() {
    return {};
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    userType() {
      return this.$store.state.auth.user.user_type;
    },
    userRole() {
      return this.$store.state.auth.user.role;
    },
  },
  created() {
    if (
      this.loggedIn &&
      window.location.pathname !== `/${this.$i18n.locale}/users/verify`
    ) {
      let user_type = this.userType;
      let role = this.userRole;
      if (role == "admin") {
        window.location.href = "/admins/homepage";
      } else {
        if (user_type == "student") {
          window.location.href = "/students/homepage";
        } else if (user_type == "teacher") {
          window.location.href = "/teachers/homepage";
        } else if (user_type == "employee") {
          window.location.href = "/employees/homepage";
        }
      }
    }
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();

      //  continue to next page
      next();
    });
    // //  hook the progress bar to finish after we've finished moving router-view
    // this.$router.afterEach(() => {
    //   //  finish the progress bar
    //   this.$Progress.finish();
    // })
  },
};
</script>

<style lang="css"></style>
