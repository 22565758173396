import Vue from 'vue';
import App from './App.vue';
import store from '@/store';
import {router} from '@/router/visitors.js';
import { i18n } from '@/i18n.js';
import VueProgressBar from 'vue-progressbar';
import VueMeta from 'vue-meta';
import VeeValidate from 'vee-validate';

import LazyLoadDirective from "@/directives/LazyLoadDirective.js";

Vue.directive("lazyload", LazyLoadDirective);

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  //solid icons shared
  faSearchPlus,
  faSearch,
  faSearchMinus,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,

} from '@fortawesome/free-solid-svg-icons';

library.add(
  //solid icons shared
  faSearchPlus,
  faSearch,
  faSearchMinus,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


Vue.component('font-awesome-icon', FontAwesomeIcon);


import Notifications from 'vue-notification';
import velocity from 'velocity-animate';

Vue.use(Notifications, { velocity });

import VueCalendly from 'vue-calendly';
Vue.use(VueCalendly);

Vue.use(VeeValidate, {
  locale: 'en',
  dictionary: {
    en: {
      custom: {
        username: {
          regex: () => 'Username may only contain letters or numbers',
          required: () => 'Username is required',
          min: () => 'Username must be at least 4 character long',
          max: () => 'Username maximum length is 30',
        },
        email: {
          required: () => 'Email is required',
          max: () => 'Email must not exceed 45 characters',
        },
        password: {
          required: () => 'Password is required',
          min: () => 'Password must be at least 8 character long',
          max: () => 'Password maximum length is 30',
        },
        password2: {
          required: () => 'Password confirmation is required',
          min: () => 'Password confirmation must be at least 8 character long',
          max: () => 'Password confirmation maximum length is 30',
          confirmed: () => 'Passwords do not match',
        },
      }
    }
  }
});

const options = {
  color: '#40e876',
  failedColor: '#874b4b',
  thickness: '5px',
  position: 'relative',
  transition: {
    speed: '0.4s',
    opacity: '1',
    termination: 300
  },
  autoRevert: true,
  inverse: false
};

Vue.use(VueProgressBar, options);

Vue.use(VueMeta, {
 // optional pluginOptions
 refreshOnceOnNavigation: true
});

Vue.config.productionTip = false;

//translate props plugin
Vue.use({
  install(Vue) {
    Vue.prototype.localizedTextFct = function (page_name, text_name, variables = {}) {
      return this.$t(page_name + '.' + text_name, variables);
    }
  }
});


new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
