<template lang="html">
  <div class="container-fluid">
    <div class="page" ref="page1" id="page1">
      <transition name="fade" mode="out-in">
        <div v-if="current_tab === 'main'" class="main-text" :key="1">
          <div class="main-title">Promi</div>
          <div class="row">
            <div class="col-6 text-center">
              <div class="sub-title">Learn anything</div>
            </div>
            <div class="col-6 text-center">
              <div class="sub-title">Teach anything</div>
            </div>
          </div>
        </div>
        <div v-else class="marketing-message-title" :key="current_title">
          {{ current_title }}
        </div>
      </transition>
      <div>
        <img
          class="img-fluid mx-auto d-block mt-4 resize"
          :class="current_tab != 'main' ? 'resize--activated' : ''"
          src="@/assets/img/Cute_fire_small_size.png"
          alt="cute fire"
        />
      </div>
      <transition name="fade" mode="out-in">
        <QuestionTab
          v-if="current_tab === 'initial_quiz'"
          :key="current_question_index"
          :current_question="initial_current_question"
          :hide_nav="true"
          @select-option="initialQuestionSelectOption"
          @go-back="goBack"
          @skip="Skip"
          @next="Next"
        />
        <Login v-if="current_tab === 'login'" />
        <Register
          v-if="current_tab === 'register'"
          :user_type="user_type"
          :email="email"
        />
        <QuestionTab
          v-if="current_tab === 'quiz'"
          :key="current_question_index"
          :current_question="current_question"
          @select-option="selectOption"
          @go-back="goBack"
          @skip="Skip"
          @next="Next"
        />
      </transition>
      <div v-show="current_tab == 'main'" class="begin">
        <button class="my-router-link" @click="current_tab = 'initial_quiz'">
          {{ localizedTextFct("buttons", "begin") }}
        </button>
      </div>

      <!-- Calendly inline widget begin -->
      <transition name="fade" mode="out-in">
        <vue-calendly
          v-if="current_tab === 'calendly'"
          :url="
            'https://calendly.com/olimprometheus?hide_landing_page_details=1&hide_gdpr_banner=1&primary_color=d3d5f5' +
            '&' +
            'name=' +
            this.name +
            '&' +
            'email=' +
            this.email
          "
          :height="400"
          :width="320"
        ></vue-calendly>
      </transition>
      <!-- Calendly inline widget end -->
      <transition name="fade" mode="out-in">
        <div v-if="event_scheduled && current_tab === 'calendly'" key="final_message">
          <div class="finish-message">
            Great job scheduling your first lesson! Meanwhile do you want to continue your
            registration to Promi?
          </div>
          <div class="option option--finish" @click="current_tab = 'register'">
            <div>Register</div>
            <font-awesome-icon class="nav-icon" icon="arrow-right" />
          </div>
        </div>
      </transition>

      <div class="option nav-button" @click="scrollTo('page2')">
        Learn More<font-awesome-icon class="nav-icon" icon="arrow-down" />
      </div>
    </div>
    <div class="page page--full container-fluid" ref="page2" id="page2">
      <div class="marketing-message-title">What do we offer?</div>
      <div class="variants mt-4 row">
        <div class="variant col-lg-4 col-sm-6 col-12">
          <div class="group-lessons">
            <div class="variant__title">Group Lessons</div>
            <div class="variant__image-container">
              <img
                class="variant__image img-fluid"
                src="@/assets/img/group lessons.jpg"
                alt="Group lessons"
              />
            </div>
          </div>
          <div class="benefits">
            <div class="benefits__title">Benefits of Group Lessons</div>
            <div class="accordion" id="accordion1">
              <div class="benefit" v-for="(el, i) in group_benefits" :key="i">
                <div
                  id="heading1"
                  class="benefit__title"
                  data-toggle="collapse"
                  :data-target="'#collapse' + i"
                  aria-expanded="true"
                  :aria-controls="'#collapse' + i"
                >
                  <img class="benefit__icon" :src="el.asset" :alt="el.alt" />
                  <div class="benefit__text">{{ el.title }}</div>
                </div>

                <div
                  :id="'collapse' + i"
                  class="collapse"
                  :aria-labelledby="'heading' + i"
                  data-parent="#accordion1"
                >
                  <div class="benefit__content">
                    {{ el.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="variant col-lg-4 col-sm-6 col-12">
          <div class="variant__title">One on one lessons</div>
          <div class="variant__image-container">
            <img
              class="variant__image img-fluid"
              src="@/assets/img/online lesson 2.png"
              alt="Group lessons"
            />
          </div>
          <div class="benefits">
            <div class="benefits__title">Benefits of One-on-One Lessons</div>
            <div class="accordion" id="accordion2">
              <div class="benefit" v-for="(el, i) in lesson_benefits" :key="i">
                <div
                  id="heading2"
                  class="benefit__title"
                  data-toggle="collapse"
                  :data-target="'#collapse2' + i"
                  aria-expanded="true"
                  :aria-controls="'#collapse2' + i"
                >
                  <img class="benefit__icon" :src="el.asset" :alt="el.alt" />
                  <div class="benefit__text">{{ el.title }}</div>
                </div>

                <div
                  :id="'collapse2' + i"
                  class="collapse"
                  :aria-labelledby="'heading2' + i"
                  data-parent="#accordion2"
                >
                  <div class="benefit__content">
                    {{ el.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="variant col-lg-4 col-sm-6 col-12">
          <div class="variant__title">Interactive Courses</div>
          <div class="variant__image-container">
            <img
              class="variant__image img-fluid"
              src="@/assets/img/create courses.png"
              alt="Group lessons"
            />
          </div>
          <div class="benefits">
            <div class="benefits__title">Benefits of Online Lessons</div>
            <div class="accordion" id="accordion3">
              <div class="benefit" v-for="(el, i) in courses_benefits" :key="i">
                <div
                  id="heading3"
                  class="benefit__title"
                  data-toggle="collapse"
                  :data-target="'#collapse3' + i"
                  aria-expanded="true"
                  :aria-controls="'#collapse3' + i"
                >
                  <img class="benefit__icon" :src="el.asset" :alt="el.alt" />
                  <div class="benefit__text">{{ el.title }}</div>
                </div>

                <div
                  :id="'collapse3' + i"
                  class="collapse"
                  :aria-labelledby="'heading3' + i"
                  data-parent="#accordion3"
                >
                  <div class="benefit__content">
                    {{ el.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="variant col-lg-3 col-sm-6 col-12">
          <div class="variant__title">AI learning</div>
          <div class="variant__image-container">
            <img
              class="variant__image img-fluid"
              src="@/assets/img/ai learning.png"
              alt="Group lessons"
            />
          </div>
          <div class="benefits">
            <div class="benefits__title">Benefits of AI learning</div>
            <div class="accordion" id="accordion4">
              <div class="benefit" v-for="(el, i) in lesson_benefits" :key="i">
                <div
                  id="heading4"
                  class="benefit__title"
                  data-toggle="collapse"
                  :data-target="'#collapse4' + i"
                  aria-expanded="true"
                  :aria-controls="'#collapse4' + i"
                >
                  <img class="benefit__icon" :src="el.asset" :alt="el.alt" />
                  <div class="benefit__text">{{ el.title }}</div>
                </div>

                <div
                  :id="'collapse4' + i"
                  class="collapse"
                  :aria-labelledby="'heading4' + i"
                  data-parent="#accordion4"
                >
                  <div class="benefit__content">
                    {{ el.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="option nav-button" @click="scrollTo('page3')">
        Learn More<font-awesome-icon class="nav-icon" icon="arrow-down" />
      </div> -->
    </div>
    <!-- <div class="page page--full" ref="page3"></div> -->

    <transition name="fade" mode="out-in" appear>
      <div
        v-show="windowScrollY > 800"
        @click="scrollTo('top')"
        class="option option--sticky"
      >
        <font-awesome-icon class="nav-icon" icon="arrow-up" />
      </div>
    </transition>
    <Footer />
  </div>
</template>

<script>
//import services
import AuthService from "@/services/AuthService.js";

import QuestionTab from "@/components/common/QuestionTab.vue";
import Login from "@/components/visitors/Login.vue";
import Register from "@/components/visitors/Register.vue";
import Footer from "@/components/common/Footer.vue";

//import mixins
import windowScroll from "@/mixins/windowScroll.js";
import calendlyListener from "@/mixins/calendlyListener.js";

export default {
  name: "Index",
  metaInfo: {
    title: "Index",
  },

  components: {
    QuestionTab,
    Login,
    Register,
    Footer,
  },
  mixins: [windowScroll, calendlyListener],

  props: {
    questions: {
      type: Array,
      default: () => [
        {
          question: "What do you want to learn?",
          question_index: 0,
          first_question: true,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Math", "English", "Spanish", "Other subject"],
          next_question_index: [1, 16, 16, 16],
        },
        {
          question: "Are you a parent or a student?",
          question_index: 1,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Parent", "Student", "Student(18+)"],
          next_question_index: [10, 2, 2],
        },
        {
          question: "What is your current level?",
          question_index: 2,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Preschool",
            "Kindergarten",
            "Elementary School (grades 1 - 5)",
            "Middle School (grades 6 - 8)",
            "High School (grades 9 - 12)",
            "College/University",
            "Homeschooling",
            "Self-Learner",
          ],
          next_question_index: [3, 3, 3, 3, 4, 5, 4, 5],
        },
        {
          question: "What subject do you need help with?",
          question_index: 3,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Algebra", "Geometry", "Math in general", "Other"],
          next_question_index: [6, 6, 6, 6],
        },
        {
          question: "What subject do you need help with?",
          question_index: 4,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Algebra 1",
            "Geometry",
            "Algebra 2",
            "Trigonometry",
            "Pre - Algebra",
            "Pre - Calculus",
            "Calculus",
            "SAT Test Prep",
            "ACT Test Prep",
            "Statistics",
            "Math in general",
            "Other",
          ],
          next_question_index: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        },
        {
          question: "What subject do you need help with?",
          question_index: 5,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "College Algebra",
            "Calculus",
            "Pre - Calculus",
            "Statistics",
            "Discrete Math",
            "Probability Theory",
            "Differential Equations",
            "Linear Algebra",
            "Group Theory",
            "Combinatorics",
            "Math in general",
            "Other",
          ],
          next_question_index: [6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6],
        },
        {
          question: "What is your goal?",
          question_index: 6,
          answer_type: "multiple_choice",
          next_button: "Next",
          options: [
            "I need help with a current class",
            "I just need to review previous material",
            "I am preparing for a test",
            "I want to learn something new",
            "Other",
          ],
          next_question_index: [7],
        },
        {
          question: "What is your first name?*",
          question_index: 7,
          answer_type: "input",
          input_name: "name",
          placeholder: "Please enter your first name",
          validation: "required|alpha_spaces|max:30",
          next_button: "Next",
          next_question_index: [8],
        },
        {
          question: "What is your email address?*",
          question_index: 8,
          answer_type: "input",
          input_name: "email",
          show_checkbox: true,
          placeholder: "Please enter your email address",
          validation: "required|email|max:45",
          next_button: "Next",
          next_question_index: [9],
        },
        {
          question: "What is your phone number?",
          question_index: 9,
          input_name: "tel",
          last_question: true,
          placeholder: "(888) 888-8888",
          validation: {
            regex: /^(\([0-9]{3}\)|[0-9]{3})[ -]?[0-9]{3}[ -]?[0-9]{4}$/,
          },
          answer_type: "input",
          next_button: "Next",
          next_question_index: [10],
        },
        {
          question: "What is your child's current educational level?",
          question_index: 10,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Preschool",
            "Kindergarten",
            "Elementary School (grades 1 - 5)",
            "Middle School (grades 6 - 8)",
            "High School (grades 9 - 12)",
            "College/University",
            "Homeschooling",
            "Self-Learner",
          ],
          next_question_index: [11, 11, 11, 11, 12, 13, 12, 13],
        },
        {
          question: "What subject does your child needs help with?",
          question_index: 11,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["Algebra", "Geometry", "Math in general", "Other"],
          next_question_index: [14, 14, 14, 14],
        },
        {
          question: "What subject does your child needs help with?",
          question_index: 12,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "Algebra 1",
            "Geometry",
            "Algebra 2",
            "Trigonometry",
            "Pre - Algebra",
            "Pre - Calculus",
            "Calculus",
            "SAT Test Prep",
            "ACT Test Prep",
            "Statistics",
            "Math in general",
            "Other",
          ],
          next_question_index: [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14],
        },
        {
          question: "What subject does your child needs help with?",
          question_index: 13,
          answer_type: "single_choice",
          next_button: "Skip",
          options: [
            "College Algebra",
            "Calculus",
            "Pre - Calculus",
            "Statistics",
            "Discrete Math",
            "Probability Theory",
            "Differential Equations",
            "Linear Algebra",
            "Group Theory",
            "Combinatorics",
            "Math in general",
            "Other",
          ],
          next_question_index: [14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14, 14],
        },
        {
          question: "Why are you seeking help?",
          question_index: 14,
          answer_type: "multiple_choice",
          next_button: "Next",
          options: [
            "My child needs help with a current class",
            "My child needs to review previous material",
            "My child needs help for an upcoming test",
            "My child wants to learn something new",
            "Other",
          ],
          next_question_index: [15],
        },
        {
          question: "What is your first name?*",
          question_index: 15,
          answer_type: "input",
          placeholder: "Please enter your first name",
          validation: "required|alpha_spaces|max:30",
          next_button: "Next",
          next_question_index: [16],
        },
        {
          question: "What is your email address?*",
          question_index: 16,
          answer_type: "input",
          show_checkbox: true,
          placeholder: "Please enter your email address",
          validation: "required|email|max:45",
          next_button: "Next",
          next_question_index: [17],
        },
        {
          question: "What is your phone number?",
          question_index: 17,
          last_question: true,
          placeholder: "(888) 888-8888",
          validation: {
            regex: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
          },
          answer_type: "input",
          next_button: "Next",
          next_question_index: [10],
        },
        // Add more questions as needed
      ],
    }, // Array of question objects (each with a question and options)
  },

  data() {
    return {
      current_tab: "main",
      show_quiz: false,

      user_type: "",

      current_question_index: 0,
      selected_questions: [0],

      user_input: {},
      current_title: "Welcome to Promi!",
      privacy_policy_consent: false,
      promotional_email_consent: false,

      name: "",
      email: "",
      phone: "",

      initial_questions: [
        {
          question: "Are you new here?",
          question_index: 0,
          first_question: true,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["I am a new user", "I am an existing user"],
          next_question_index: [1, 2],
        },
        {
          question: "What are your goals?",
          question_index: 1,
          first_question: true,
          answer_type: "single_choice",
          next_button: "Skip",
          options: ["I wan't to learn!", "I'm here to teach!"],
          next_question_index: [2, 3, 2],
        },
      ],

      group_benefits: [
        {
          alt: "one",
          asset: require("@/assets/img/one.svg"),
          title: "Social Interaction",
          content:
            "Opportunities to learn from peers and collaborate in a group setting.",
        },
        {
          alt: "two",
          asset: require("@/assets/img/two.svg"),
          title: "Teamwork Skills",
          content: "Develops collaboration, communication, and leadership abilities.",
        },
        {
          alt: "three",
          asset: require("@/assets/img/three.svg"),
          title: "Cost-Effective",
          content: "Much more affordable than 1 on 1 lesson.",
        },
        {
          alt: "four",
          asset: require("@/assets/img/four.svg"),
          title: "Peer Support",
          content: "Our students help each other.",
        },
        {
          alt: "five",
          asset: require("@/assets/img/five.svg"),
          title: "Fun and Enjoyment",
          content: "Learn while making new friends!",
        },
      ],
      lesson_benefits: [
        {
          alt: "one",
          asset: require("@/assets/img/one.svg"),
          title: "Personalized Attention",
          content: "Tailored lessons cater to individual learning styles and needs.",
        },
        {
          alt: "two",
          asset: require("@/assets/img/two.svg"),
          title: "Customized Pace",
          content:
            "Learners progress at their own speed without being rushed or held back.",
        },
        {
          alt: "three",
          asset: require("@/assets/img/three.svg"),
          title: "Immediate Feedback",
          content:
            "Prompt correction of mistakes and reinforcement of correct practices.",
        },
        {
          alt: "four",
          asset: require("@/assets/img/four.svg"),
          title: "Increased Engagement",
          content:
            "Higher interaction levels encourage active participation and deeper understanding.",
        },
        {
          alt: "five",
          asset: require("@/assets/img/five.svg"),
          title: "Builds Confidence",
          content: "Supportive environment boosts confidence and willingness to learn.",
        },
      ],
      courses_benefits: [
        {
          alt: "one",
          asset: require("@/assets/img/one.svg"),
          title: "Personalized Education",
          content: "Courses designed just for you and your needs.",
        },
        {
          alt: "two",
          asset: require("@/assets/img/two.svg"),
          title: "Progress Tracking",
          content:
            " Our online courses help you stay organized in your educational journey.",
        },
        {
          alt: "three",
          asset: require("@/assets/img/three.svg"),
          title: "Everything you need in one place!",
          content:
            "Teachers use our website to create exercises and assign homeworks after lessons.",
        },
        {
          alt: "four",
          asset: require("@/assets/img/four.svg"),
          title: "Engaging Multimedia Learning",
          content:
            "Our online courses utilize multimedia such as videos, animations, and interactive quizzes to make learning dynamic and engaging. This variety keeps students actively involved and helps cater to different learning styles.",
        },
        {
          alt: "five",
          asset: require("@/assets/img/five.svg"),
          title: "Accessibility and Convenience",
          content:
            "Enjoy flexibility and accessibility, allowing learning from anywhere at any pace.",
        },
      ],
    };
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query?.skip_intro) {
      this.current_tab = "quiz";
    }
  },
  computed: {
    initial_current_question() {
      return this.initial_questions[this.current_question_index];
    },
    current_question() {
      return this.questions[this.current_question_index];
    },
  },
  methods: {
    isCalendlyEvent(e) {
      return (
        e.origin === "https://calendly.com" &&
        e.data.event &&
        e.data.event.indexOf("calendly.") === 0
      );
    },
    scrollTo(refId) {
      if (refId === "top") {
        return window.scrollTo({ top: 0, behavior: "smooth" });
      }
      const el = this.$refs[refId];

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    goBack() {
      if (this.current_question_index === 0) {
        return;
      }
      let key = this.selected_questions.pop();
      delete this.user_input[key];
      this.current_question_index = this.selected_questions[
        this.selected_questions.length - 1
      ];
    },
    async Skip() {
      this.user_input[this.current_question_index] = "";
      this.current_question_index = this.questions[
        this.current_question_index
      ].next_question_index[0];
      this.selected_questions.push(this.current_question_index);
    },
    async Next(input) {
      try {
        if (this.questions[this.current_question_index]?.last_question) {
          this.user_input[this.current_question_index] = input;
          let quiz_answers = [];
          for (const key in this.user_input) {
            if (Object.hasOwnProperty.call(this.user_input, key)) {
              const element = this.user_input[key];
              if (key == 8 || key == 16) {
                this.email = element;
              } else if (key == 7 || key == 15) {
                this.name = element;
              } else if (key == 9 || key == 17) {
                this.phone = element;
              } else {
                quiz_answers.push({
                  question: this.questions[key].question,
                  ...(Array.isArray(element) && {
                    answers: element,
                    question_type: "multi_choice",
                  }),
                  ...(!Array.isArray(element) && {
                    answer: element,
                    question_type: "single_choice",
                  }),
                });
              }
            }
          }
          await AuthService.saveCustomerAnswers(
            this.name,
            this.email,
            this.phone,
            this.privacy_policy_consent,
            this.promotional_email_consent,
            quiz_answers
          );
          this.current_tab = "calendly";
          this.current_title = "Congrats! You've unlocked a free lesson!";
        } else if (this.questions[this.current_question_index]?.show_checkbox) {
          this.user_input[this.current_question_index] = input.email;
          this.privacy_policy_consent = input.privacy_policy_consent;
          this.promotional_email_consent = input.promotional_email_consent;
          this.current_question_index = this.questions[
            this.current_question_index
          ].next_question_index[0];
          this.selected_questions.push(this.current_question_index);
        } else {
          this.user_input[this.current_question_index] = input;
          this.current_question_index = this.questions[
            this.current_question_index
          ].next_question_index[0];
          this.selected_questions.push(this.current_question_index);
        }
      } catch (error) {
        console.log(error);
      }
    },
    selectOption(option) {
      let next_question_index = this.questions[this.current_question_index]
        .next_question_index[
        this.questions[this.current_question_index].options.indexOf(option)
      ];
      if (next_question_index === 16) {
        this.current_tab = "register";
        return;
      }

      // Save the selected answer (you can handle this in the parent component)
      this.user_input[this.current_question_index] = option;

      this.current_question_index = next_question_index;
      this.selected_questions.push(this.current_question_index);
    },

    initialQuestionSelectOption(option) {
      this.user_input[this.current_question_index] = option;

      let next_question_index = this.initial_questions[this.current_question_index]
        .next_question_index[
        this.initial_questions[this.current_question_index].options.indexOf(option)
      ];

      if (this.current_question_index === 0) {
        if (next_question_index === 1) {
          this.current_question_index = 1;
        } else if (next_question_index === 2) {
          this.current_tab = "login";
          this.current_title = "Welcome Back!";
        }
      } else if (this.current_question_index === 1) {
        if (next_question_index === 2) {
          this.user_type = "student";
          this.current_tab = "quiz";
          this.selected_questions = [0];
          this.user_input = {};
          this.current_question_index = 0;
        } else if (next_question_index === 3) {
          this.user_type = "teacher";
          this.current_tab = "register";
        }
      }
    },

    regRed() {
      this.$router.push({ name: "register" });
    },
  },
  watch: {
    event_scheduled() {
      if (this.event_scheduled) {
        this.current_title = "Thank you! We are looking forward to seeing you soon!";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
@import "@/css/_button.scss";
@import "@/css/_animations.scss";

.finish-message {
  font-size: $fontSizeHeader;
  text-align: center;
  margin-top: $lap;
}

.main-title {
  text-align: center;
  font-weight: 500;
  font-size: $fontSizeMegaLarge;
}

.sub-title {
  font-size: $fontSizeMegaLarge;
  font-weight: 400;
}

$buttonBgColor: rgb(60, 123, 255);
$buttonBorderColor: rgb(27, 101, 255);
$buttonTextColor: white;
$buttonBgColorHover: rgb(0, 57, 177);
$buttonBorderColorHover: rgb(27, 101, 255);
$buttonTextColorHover: white;

.begin {
  margin-top: 2 * $lap;
  text-align: center;
}

.my-router-link {
  width: 266px;
  height: 66px;
  border-radius: 33px;
  font-size: 30px;
  font-family: Arial;
  color: $buttonTextColor;
  border: 4px solid $buttonBorderColor;
  background-color: $buttonBgColor;
  outline: none;
}

.my-router-link:hover {
  text-decoration: none;
  color: $buttonTextColorHover;
  border: 4px solid $buttonBorderColorHover;
  background-color: $buttonBgColorHover;
}

.resize {
  width: 166px;
  height: 250px;
  transition: all 0.4s ease-in-out;
}

.resize--activated {
  width: 66px;
  height: 100px;
}

.point {
  text-align: center;
}

.page {
  height: calc(100vh - 56px);
  position: relative;
  &--full {
    min-height: 100vh;
    height: auto;
  }
}

.variant__title {
  text-align: center;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  font-size: $fontSizeMegaLarge;
}

.variant__image {
  &-container {
    text-align: center;
  }
}

.variants {
}

.variant {
}

.benefits {
  text-align: center;
  padding: 4px;

  &__title {
    font-size: $fontSizeExtraLarge;
    margin-bottom: $lap;
  }
}

.benefit {
  &__icon {
    width: 3 * $lap;
    height: 3 * $lap;
    filter: invert(6%) sepia(64%) saturate(28%) hue-rotate(314deg) brightness(109%)
      contrast(88%);
  }

  &__text {
    margin-left: auto;
    margin-right: auto;
    padding-right: 3 * $lap;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $fontSizeLarge;
    border: 1px solid #ddd;
    border-radius: $f5;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &__content {
    padding: $f6;
  }
}

#calendryWidget {
  border-radius: $gridElementBorderRadius;
}

.logo-img {
  display: block;
  max-width: 200px;
  max-height: 100px;
  width: auto;
  height: auto;
}

.marketing-message-title {
  font-size: 2 * $fontSizeMegaLarge;
  font-family: $fontFamilyChat;
  font-weight: 400;
  text-align: center;
}

.question-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center horizontally */
  justify-content: center;
  /* Center vertically */
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  height: 100%;
}

.question {
  font-size: $fontSizeMegaLarge;
  font-family: $fontFamilyCommon;
  margin-bottom: $lap;
}

.option {
  cursor: pointer;
  padding: $f6;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: $f5;
  margin-bottom: $f4;
  transition: background-color 0.2s ease;

  &--finish {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &--sticky {
    position: fixed;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    right: 0;
    margin-left: auto;
    margin-right: $f6;
    margin-bottom: $f6;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.option:hover {
  background-color: #f0f0f0;
}

.nav {
  &-button {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: $f6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 2 * $f9;
    color: rgba(0, 0, 0, 0.5);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.selected {
  background-color: #e0e0e0;
}

.calendly {
  height: 400px;
}

@media screen and (max-width: 768px) {
  .marketing-message-title {
    font-size: $fontSizeExtraLarge;
  }

  .variant__title {
    font-size: $fontSizeLarge;
  }
  .variant__image {
    width: 200px;
  }
  .benefit__title {
    font-size: $fontSizeHeader;
  }
  .benefit__icon {
    width: 2 * $lap;
    height: 2 * $lap;
  }
}

@media screen and (max-height: 800px) {
  .nav-button {
    padding: $f5;
    font-size: $fontSizeMedium;
  }
  .calendly {
    height: 300px;
  }

  #page1 {
    min-height: calc(100vh - 56px);
    height: auto;
  }
}
</style>
